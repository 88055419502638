import React from 'react'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { useCartContext } from 'context/CartContext'
import Link from 'components/foundations/Link/Link'

export default function OrderSuccessTemplate() {
  const [, dispatch] = useCartContext()

  React.useEffect(() => {
    dispatch({ type: 'EMPTY_CART', payload: {} })
  }, [])

  return (
    <>
      <div>
        <Typography variant="h3">Ordine completato con successo!</Typography>
        <Typography variant="body1">
          Riceverai una mail con la conferma dell&apos;ordine una volta che sarà processato dai nostri operatori. <br />
        </Typography>
      </div>
      <p />
      <div>
        <Link to={`/`}>
          <Button className="btn btn-primary">
            <Typography component="h2" variant="button">
              Clicca qui per tornare alla home
            </Typography>
          </Button>
        </Link>
      </div>
    </>
  )
}
